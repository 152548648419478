<template>
    <div class="xbk">
        <div style="display: flex">
            <el-input v-model.trim="form.title">
                <template slot="prepend">标题</template>
            </el-input>
            <el-input v-model.trim="form.intro">
                <template slot="prepend">文字信息</template>
            </el-input>
        </div>
        <div class="m" style="margin-top: 20px">
            <span style="margin-right: 10px">上传图标：</span>
            <el-upload
                style="display: flex"
                :headers="{ token: token }"
                :limit="1"
                name="thumbnail"
                :action="imgBaseUrl + '/api/Project/upload'"
                list-type="picture-card"
                :multiple="false"
                :on-success="
                    res => {
                        success(res);
                    }
                "
                :on-remove="
                    () => {
                        remove();
                    }
                "
                :file-list="fileList"
            >
                <i class="el-icon-plus"></i>
            </el-upload>
            <div class="is">
                <i class="el-icon-circle-plus-outline" @click="$emit('add', item.td)"></i>
                <i class="el-icon-remove-outline" @click="$emit('clear', item.td, item.key)"></i>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";

export default {
    props: {
        item: Object,
    },
    data() {
        return {
            form: {
                title: "",
                image: "",
                intro: "",
                td: null,
            },
            fileList: [],
            token: "",
        };
    },
    created() {
        if (this.item.isSet) {
            let { title, image, intro } = this.item;
            this.form = { title, image, intro };
            this.fileList = image ? [{ name: "", url: image }] : [];
        }
        this.token = Cookies.get("token");
        this.form.td = this.item.td;
    },
    methods: {
        getData() {
            return { ...this.form };
        },
        success(url) {
            this.form.image = axios.defaults.baseURL + url;
        },
        remove() {
            this.form.image = "";
        },
    },
};
</script>

<style lang="less" scoped>
.xbk {
    margin-bottom: 10px;
}
.m {
    display: flex;
    position: relative;
    .is {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 24px;
    }
}
</style>
