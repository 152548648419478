var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable"},[_vm._v("标题名")]),_c('div',{staticClass:"content"},[_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable"},[_vm._v("简介")]),_c('div',{staticClass:"content"},[_c('el-input',{model:{value:(_vm.form.intro),callback:function ($$v) {_vm.$set(_vm.form, "intro", $$v)},expression:"form.intro"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable"},[_vm._v("标识")]),_c('div',{staticClass:"content"},[_c('el-input',{model:{value:(_vm.form.mark),callback:function ($$v) {_vm.$set(_vm.form, "mark", $$v)},expression:"form.mark"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable"},[_vm._v("选择分类")]),_c('div',{staticClass:"content"},[_c('el-cascader',{attrs:{"options":_vm.options},model:{value:(_vm.form.pid),callback:function ($$v) {_vm.$set(_vm.form, "pid", $$v)},expression:"form.pid"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable",staticStyle:{"height":"148px","line-height":"148px"}},[_vm._v("图标")]),_c('div',{staticClass:"content"},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"on-success":res => {
                            _vm.success(res, 'icon');
                        },"on-remove":() => {
                            _vm.remove('icon');
                        },"file-list":_vm.fileList_icon}},[_c('i',{staticClass:"el-icon-plus"})])],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable",staticStyle:{"height":"148px","line-height":"148px"}},[_vm._v("背景图")]),_c('div',{staticClass:"content"},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"on-success":res => {
                            _vm.success(res, 'bg_image');
                        },"on-remove":() => {
                            _vm.remove('bg_image');
                        },"file-list":_vm.fileList_bg}},[_c('i',{staticClass:"el-icon-plus"})])],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable",staticStyle:{"height":"148px","line-height":"148px"}},[_vm._v("广告图")]),_c('div',{staticClass:"content"},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"on-success":res => {
                            _vm.success(res, 'advert_image');
                        },"on-remove":() => {
                            _vm.remove('advert_image');
                        },"file-list":_vm.fileList_advert}},[_c('i',{staticClass:"el-icon-plus"})])],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable"},[_vm._v("置顶")]),_c('div',{staticClass:"content"},[_c('el-switch',{attrs:{"active-color":"#7781f1","inactive-color":"#ff4949","active-text":"是","inactive-text":"否"},model:{value:(_vm.form.is_top),callback:function ($$v) {_vm.$set(_vm.form, "is_top", $$v)},expression:"form.is_top"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable"},[_vm._v("状态")]),_c('div',{staticClass:"content"},[_c('el-switch',{attrs:{"active-color":"#7781f1","inactive-color":"#ff4949","active-text":"显示","inactive-text":"隐藏"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"lable"},[_vm._v("内容")]),_c('div',{staticClass:"content"},[_c('el-switch',{attrs:{"active-color":"#7781f1","inactive-color":"#ff4949","active-text":"板块","inactive-text":"富文本"},model:{value:(_vm.form.sift),callback:function ($$v) {_vm.$set(_vm.form, "sift", $$v)},expression:"form.sift"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.form.sift),expression:"!form.sift"}]},[_c('editor',{attrs:{"txt":_vm.form.ueditorData,"getTxt":txt => {
                        _vm.form.ueditorData = txt;
                    }}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.sift),expression:"form.sift"}]},[_c('el-form',{attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"上标题"}},[_c('el-input',{model:{value:(_vm.form.plate_top),callback:function ($$v) {_vm.$set(_vm.form, "plate_top", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.plate_top"}})],1),_c('el-form-item',{attrs:{"label":"上小版块"}},_vm._l((_vm.topList),function(el){return _c('xbk',{key:el.key,ref:'top' + el.key,refInFor:true,attrs:{"item":{ ...el, td: 1 }},on:{"add":_vm.add,"clear":_vm.clear}})}),1),_c('el-form-item',{attrs:{"label":"下标题"}},[_c('el-input',{model:{value:(_vm.form.plate_bottom),callback:function ($$v) {_vm.$set(_vm.form, "plate_bottom", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.plate_bottom"}})],1),_c('el-form-item',{attrs:{"label":"下小版块"}},_vm._l((_vm.bottomList),function(el){return _c('xbk',{key:el.key,ref:'bottom' + el.key,refInFor:true,attrs:{"item":{ ...el, td: 2 }},on:{"add":_vm.add,"clear":_vm.clear}})}),1)],1)],1)]),_c('div',{staticClass:"page_fab"},[_c('VyIcon',{attrs:{"name":"确认","scale":"4"},nativeOn:{"click":function($event){return _vm.yes.apply(null, arguments)}}}),_c('VyIcon',{attrs:{"name":"取消","scale":"4"},nativeOn:{"click":function($event){return _vm.no.apply(null, arguments)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }