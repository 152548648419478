<template>
    <div class="page">
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>解决方案</el-breadcrumb-item>
                <el-breadcrumb-item>全部方案</el-breadcrumb-item>
                <el-breadcrumb-item v-if="!(s == 1)">{{ s == 2 ? "添加" : s == 3 ? "编辑" : "" }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary" v-show="s == 1">
            <div>
                <vy-search-box
                    v-model="value"
                    :btn-click="search"
                    placeholder="请输入内容"
                    :select-list="[
                        { label: '富文本', value: 'ueditorData' },
                        { label: '板块', value: 'intro' },
                    ]"
                    :select-value="select"
                    :select-clearable="false"
                    @change="val => (select = val)"
                    select
                ></vy-search-box>
            </div>
            <div class="_add">
                <button @click="s = 2">添加</button>
            </div>
        </div>
        <div class="page_content" v-show="s == 1">
            <el-table :data="tableData" border>
                <el-table-column prop="id" align="center" label="id"> </el-table-column>
                <el-table-column prop="title" align="center" label="标题"> </el-table-column>
                <el-table-column prop="" align="center" label="分类">
                    <template slot-scope="{ row }">
                        <span>{{ `${row.category[0].first_title} > ${row.category[0].second_title}` }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="update_time" align="center" label="更新时间"> </el-table-column>
                <el-table-column prop="is_top" align="center" label="置顶">
                    <template slot-scope="{ row }">
                        <span>{{ row.is_top ? "是" : "否" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" align="center" label="状态">
                    <template slot-scope="{ row }">
                        <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="mark" align="center" label="标记"> </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="{ row }">
                        <div class="_operate">
                            <button @click="openSet(row)">编辑</button>
                        </div>
                        <div class="_delete">
                            <button @click="del(row)">删除</button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页器 -->
            <vy-pager
                :count="total"
                :page="page"
                :page-size="pageSize"
                @current-change="currentChange"
                @size-change="sizeChange"
            ></vy-pager>
        </div>

        <template v-if="s == 2">
            <add @stop="stop"></add>
        </template>
        <template v-if="s == 3">
            <set @stop="stop" :row="row"></set>
        </template>
    </div>
</template>

<script>
import add from "./add.vue";
import set from "./set.vue";
import axios from "axios";
import VyPager from "@/components/customs/VyPager.vue";
import VySearchBox from "@/components/customs/VySearchBox.vue";

export default {
    components: { add, set, VyPager, VySearchBox },
    data() {
        return {
            tableData: [],
            // 1全部方案 2添加 3编辑
            s: 1,
            row: {},
            select: "ueditorData",
            value: "",
            page: 1,
            total: 0,
            pageSize: 10,
        };
    },
    created() {
        this.loading();
    },
    methods: {
        // 获取列表数据
        async getData() {
            let obj = { page: this.page, pageSize: this.pageSize };
            obj[this.select] = this.value;
            let {
                data: { data, count },
            } = await axios.get("/api/solution/show", { params: { ...obj } });
            this.total = count;
            return data;
        },
        async loading() {
            let data = await this.getData();
            this.tableData = data;
        },
        openSet(row) {
            this.row = row;
            this.s = 3;
        },
        // 删除
        async del({ id }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            let { data } = await axios.delete("/api/solution/tdel", { params: { id } });
            if (data.code !== 200) return this.$message.error(data.msg);
            this.$message.success(data.msg);
            this.loading();
        },
        // 搜索
        search() {
            this.loading();
        },
        // 返回至全部方案
        stop() {
            this.loading();
            this.s = 1;
        },
        currentChange(page) {
            this.page = page;
            this.loading();
        },
        sizeChange(pageSize) {
            this.pageSize = pageSize;
            this.loading();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_headerSecondary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
</style>
