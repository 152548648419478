<template>
    <div class="main">
        <div class="form">
            <div class="item">
                <div class="lable">标题名</div>
                <div class="content"><el-input v-model="form.title"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">简介</div>
                <div class="content"><el-input v-model="form.intro"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">标识</div>
                <div class="content"><el-input v-model="form.mark"></el-input></div>
            </div>
            <div class="item">
                <div class="lable">选择分类</div>
                <div class="content">
                    <el-cascader v-model="form.pid" :options="options"></el-cascader>
                </div>
            </div>
            <div class="item">
                <div class="lable" style="height: 148px; line-height: 148px">图标</div>
                <div class="content">
                    <el-upload
                        :headers="{ token: token }"
                        :limit="1"
                        name="thumbnail"
                        :action="imgBaseUrl + '/api/Project/upload'"
                        list-type="picture-card"
                        :multiple="false"
                        :on-success="
                            res => {
                                success(res, 'icon');
                            }
                        "
                        :on-remove="
                            () => {
                                remove('icon');
                            }
                        "
                        :file-list="fileList_icon"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>
            <div class="item">
                <div class="lable" style="height: 148px; line-height: 148px">背景图</div>
                <div class="content">
                    <el-upload
                        :headers="{ token: token }"
                        :limit="1"
                        name="thumbnail"
                        :action="imgBaseUrl + '/api/Project/upload'"
                        list-type="picture-card"
                        :multiple="false"
                        :on-success="
                            res => {
                                success(res, 'bg_image');
                            }
                        "
                        :on-remove="
                            () => {
                                remove('bg_image');
                            }
                        "
                        :file-list="fileList_bg"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>
            <div class="item">
                <div class="lable" style="height: 148px; line-height: 148px">广告图</div>
                <div class="content">
                    <el-upload
                        :headers="{ token: token }"
                        :limit="1"
                        name="thumbnail"
                        :action="imgBaseUrl + '/api/Project/upload'"
                        list-type="picture-card"
                        :multiple="false"
                        :on-success="
                            res => {
                                success(res, 'advert_image');
                            }
                        "
                        :on-remove="
                            () => {
                                remove('advert_image');
                            }
                        "
                        :file-list="fileList_advert"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
            </div>
            <div class="item">
                <div class="lable">置顶</div>
                <div class="content">
                    <el-switch
                        v-model="form.is_top"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="是"
                        inactive-text="否"
                    >
                    </el-switch>
                </div>
            </div>
            <div class="item">
                <div class="lable">状态</div>
                <div class="content">
                    <el-switch
                        v-model="form.status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                    >
                    </el-switch>
                </div>
            </div>
            <div class="item">
                <div class="lable">内容</div>
                <div class="content">
                    <!-- 0富文本 1板块 -->
                    <el-switch
                        v-model="form.sift"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="板块"
                        inactive-text="富文本"
                    >
                    </el-switch>
                </div>
            </div>
            <!-- 内容>富文本 -->
            <div v-show="!form.sift">
                <editor
                    :txt="form.ueditorData"
                    :getTxt="
                        txt => {
                            form.ueditorData = txt;
                        }
                    "
                ></editor>
            </div>
            <!-- 内容>板块 -->
            <div v-show="form.sift">
                <el-form label-width="80px">
                    <el-form-item label="上标题">
                        <el-input v-model.trim="form.plate_top"></el-input>
                    </el-form-item>
                    <el-form-item label="上小版块">
                        <xbk
                            v-for="el in topList"
                            :key="el.key"
                            :item="{ ...el, td: 1 }"
                            :ref="'top' + el.key"
                            @add="add"
                            @clear="clear"
                        ></xbk>
                    </el-form-item>
                    <el-form-item label="下标题">
                        <el-input v-model.trim="form.plate_bottom"></el-input>
                    </el-form-item>
                    <el-form-item label="下小版块">
                        <xbk
                            v-for="el in bottomList"
                            :key="el.key"
                            :item="{ ...el, td: 2 }"
                            :ref="'bottom' + el.key"
                            @add="add"
                            @clear="clear"
                        ></xbk>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <!-- 悬浮按钮 -->
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="取消" scale="4" @click.native="no"></VyIcon>
        </div>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import xbk from "./xbk.vue";
import axios from "axios";
import qs from "qs";
import Editor from "@/components/customs/editor.vue";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { xbk, Editor, VyIcon },
    props: ["row"],
    data() {
        return {
            form: {
                title: "",
                status: false,
                is_top: false,
                pid: "",
                intro: "",
                icon: "",
                sift: false,
                mark: "",
                advert_image: "",
                bg_image: "",
                ueditorData: "",
                plate_top: "",
                plate_bottom: "",
                plate: [[], []],
            },
            fileList_icon: [],
            fileList_advert: [],
            fileList_bg: [],
            token: "",
            // 选择分类列表
            options: [],
            topList: [{ key: Math.random(), isSet: true, image: "", intro: "" }],
            bottomList: [{ key: Math.random(), isSet: true, image: "", intro: "" }],
        };
    },
    async created() {
        // 获取分类列表数据
        let {
            data: { data },
        } = await axios.get("/api/solution/showone");
        this.options = data.map(e => {
            return {
                value: e.id,
                label: e.title,
                children: e.children.map(el => {
                    return {
                        value: el.id,
                        label: el.title,
                    };
                }),
            };
        });
        this.token = Cookies.get("token");
        // region
        this.form = {
            ...this.row,
            status: this.row.status ? true : false,
            is_top: this.row.is_top ? true : false,
            sift: this.row.sift ? true : false,
            pid: [this.row.category[0].first_id, this.row.category[0].second_id],
        };
        this.fileList_icon = this.row.icon ? [{ name: "", url: this.row.icon }] : [];
        this.fileList_advert = this.row.advert_image ? [{ name: "", url: this.row.advert_image }] : [];
        this.fileList_bg = this.row.bg_image ? [{ name: "", url: this.row.bg_image }] : [];
        if (!this.row.plate.length) return;
        this.topList = this.row.plate
            .filter(e => e.td == 1)
            .map(e => {
                return {
                    ...e,
                    key: Math.random(),
                    isSet: true,
                };
            });
        this.bottomList = this.row.plate
            .filter(e => e.td == 2)
            .map(e => {
                return {
                    ...e,
                    key: Math.random(),
                    isSet: true,
                };
            });
        // endregion
    },
    methods: {
        async yes() {
            let { form, topList, bottomList } = this;
            let _this = this;
            let obj = {
                ...form,
                status: Number(form.status),
                is_top: Number(form.is_top),
                sift: Number(form.sift),
                plate: [
                    topList.map(e => {
                        return _this.$refs["top" + e.key][0].getData();
                    }),
                    bottomList.map(e => {
                        return _this.$refs["bottom" + e.key][0].getData();
                    }),
                ],
            };
            let { data } = await axios.put("/api/solution/tupdate", qs.stringify(obj));
            if (data.code != 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.no();
        },
        no() {
            this.$emit("stop");
        },
        add(td) {
            if (td == 1) {
                this.topList.push({ key: Math.random() });
            } else if (td == 2) {
                this.bottomList.push({ key: Math.random() });
            }
        },
        clear(td, key) {
            if (td == 1) {
                if (this.topList.length <= 1) return this.$message.error("请勿删除最后一项");
                this.topList = this.topList.filter(e => e.key !== key);
            } else if (td == 2) {
                if (this.bottomList.length <= 1) return this.$message.error("请勿删除最后一项");
                this.bottomList = this.bottomList.filter(e => e.key !== key);
            }
        },
        // 上传成功
        success(url, key) {
            this.form[key] = axios.defaults.baseURL + url;
        },
        // 删除上传图
        remove(key) {
            this.form[key] = "";
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    .form {
        // background-color: white;
        border-radius: 20px;
        padding: 40px;
        margin-top: 20px;
        .item {
            display: flex;
            align-items: center;
            margin: 20px 0;
            .lable {
                width: 80px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                color: white;
                font-size: 16px;
                border: 0;
                border-radius: 20px 0 0 20px;
                background: linear-gradient(to right, #da8cff, #9a55ff);
            }
            .content {
                flex: 1;
                display: flex;
                align-items: center;
                white-space: nowrap;

                /deep/.el-input__inner {
                    flex: 1;
                }
                /deep/.el-switch {
                    // margin-left: 20px;
                    margin: 0 20px;
                }
                /deep/.el-switch__label.is-active {
                    color: #7781f1;
                }
                /deep/.el-tag {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
